import React from 'react';
import $ from "jquery";

import { MarkerIcon } from './brand-map-icon';

const Marker = ({ text, setCurrentId, currentId, pinColor }) => {

  const greatPlaceStyle = {
    position: 'absolute',
    transform: 'translate(-75%, -75%)'
  }

  return (
    <div style={greatPlaceStyle} className="marker"  role="presentation">
        <MarkerIcon pinColor={pinColor} />
    </div>
  )
}

export default Marker;

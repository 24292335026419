import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
//import { GoogleMap, useJsApiLoader, LoadScript, InfoWindow, Marker, Circle} from "@react-google-maps/api"
import GoogleMapReact from 'google-map-react';
// import PlayVideo from "../Play/PlayVideo";
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import marker_icon from "gatsby-theme-starberry-lomondgroup/src/components/maps/img/search_map_marker.png";

import Marker from '../map/brandResultMarker';

import "./BrandsMap.scss"

  

const BrandsMap = () => {

  const BrandMapQuery = gql(`
    query BrandMapQuery{
      brands{
        id
        slug
        title
        office_headquaters
        latitude
        longitude
        radius_miles
        pointer_color_code
        banner_section{
          title
          content
          banner_image{
            alternativeText
            url
          }
        }
        imagetransforms
      }
    }
  `)

  const { data, loading, error } = useQuery(BrandMapQuery);

  //console.log("data ==>", data);

  const [render, setRender] = useState(false);
  const [center, setCenter] = useState([]);

  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);

  useEffect(()=>{
    setRender(false)
    //setNoHits([{ objectID: "noProp", _geoloc:{lat: 51.5287352, lng: -0.3817853 }}])
    if(data?.brands?.length > 0){
      setCenter({
        lat: data?.brands?.filter(item => item.latitude && item.longitude).length > 0 && parseFloat(data?.brands[0].latitude),
        lng: data?.brands?.filter(item => item.latitude && item.longitude).length > 0 && parseFloat(data?.brands[0].longitude),
      })
      setTimeout(() => {
        setRender(true)      
      }, 200);
    }
  },[data?.brands])


  // const mygetMapBounds = (map, maps, places) => {
  //   const bounds = new maps.LatLngBounds();

  //   places.forEach((place) => {
  //     bounds.extend(new maps.LatLng(
  //       place.latitude,
  //       place.longitude,
  //     ));
  //   });
  //   return bounds;
  // };

  // Re-center map when resizing the window
  // const mybindResizeListener = (map, maps, bounds) => {
  //   maps.event.addDomListenerOnce(map, 'idle', () => {
  //     maps.event.addDomListener(window, 'resize', () => {
  //       map.fitBounds(bounds);
  //     });
  //   });
  // };

  // Fit map to its bounds after the api is loaded
  const myapiHasLoaded = (map, maps, places) => {
    // Get bounds by our places
    // const bounds = mygetMapBounds(map, maps, places);
    // Fit map to bounds
    // map.fitBounds(bounds);
    // Bind the resize listener
    //mybindResizeListener(map, maps, bounds);

    places.forEach((place) => {
      new maps.Circle({
        strokeColor: place.pointer_color_code,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: place.pointer_color_code,
        fillOpacity: 0.3,
        map,
        center: { lat: parseFloat(place.latitude), lng: parseFloat(place.longitude) },
        radius: place.radius_miles
      });
    })
  };
  
  //console.log("brands center", data?.brands, center)

  return (
      <React.Fragment>

        {render &&
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GATSBY_MAPS_API_KEY }}
          yesIWantToUseGoogleMapApiInternals={true}
          options={{ styles: "" }}
          zoom={7}
          center={center}
          //onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, center)}
          onGoogleApiLoaded={({ map, maps }) => data?.brands?.filter(item => item.latitude).length > 0 &&  myapiHasLoaded(map, maps, data?.brands?.filter(item => item.latitude))}
        >

        {data?.brands?.length > 0 && data?.brands?.filter(item => item.latitude).map((item, index) => {

          return(
            <>
              <Marker key={index} lat={parseFloat(item.latitude)} lng={parseFloat(item.longitude)} pinColor={item.pointer_color_code}>

              </Marker>
            </>
          )
        })}
        </GoogleMapReact>
        }

      </React.Fragment>
  )
};
export default BrandsMap;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "gatsby-theme-starberry-lomondgroup/src/components/Counter/Counter.scss";
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";
import "./WhyChoose.scss";

const WhyChoose = ({Module}) => {

    var counter_list = Module?.why_choose_module;
    
    return (
        <section className="counter why_choose">
            <Container>
                <Row>
                    <Col>
                        <div className="counter-wrapper">
                                
                            <h2>{Module?.title}</h2>
                            <p>{Module?.description}</p>
                            
                            <div className="counter-wrap-inner">
                            {counter_list?.length > 0 && counter_list?.map((item, index) => {
                                return(
                                    <div className="counter-wrap">
                                        <div className="counter-box">
                                            <span className="box">
                                                {item.upload_icon?.url && <ImageModule ImageSrc={item.upload_icon} alt={item.name} loading="eager" /> }
                                            </span>
                                        </div>
                                        <div className="counter-content">
                                            <h3>{item.title}</h3>
                                            <p>{item.content}</p>
                                        </div>
                                    </div>
                                )
                            })}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

    );
};
export default WhyChoose;

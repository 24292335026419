import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";

import CTABtnModule from "gatsby-theme-starberry-lomondgroup/src/modules/cta_btn_module";
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";
import ReviewRatings from "gatsby-theme-starberry-lomondgroup/src/modules/review_rating_module";
import BannerSearch from "gatsby-theme-starberry-lomondgroup/src/components/Banner/Section/BannerSearch";
import BannerBtns from "gatsby-theme-starberry-lomondgroup/src/components/Banner/Section/BannerBtns";

import ValuationPage from "gatsby-theme-starberry-lomondgroup/src/components/ValuationPage/ValuationPage"
import FormsWrapper from "gatsby-theme-starberry-lomondgroup/src/components/FormPage/FormPage";
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";

import BreadCrumbs from "gatsby-theme-starberry-lomondgroup/src/components/StaticBanner/BreadCrumbs";

import "gatsby-theme-starberry-lomondgroup/src/components/Banner/Banner.scss";


const Banner = ({banner_section, GQLModules, GQLPage, location}) => {

  //console.log("choose_form", GQLPage?.choose_form);

  var imagename = "pages.banner_section_banner_image.landing_banner_image";

  let processedImages = JSON.stringify({});
  if (GQLPage?.imagetransforms?.banner_section_banner_image_Transforms) {
      processedImages = GQLPage?.imagetransforms?.banner_section_banner_image_Transforms;
  }

  return (

      <section className="banner">
        {/* <span className="banner_img" style={{backgroundImage:`url(${banner_section?.banner_image?.url})`}}></span> */}
        {banner_section?.banner_image && <ImageModule ImageSrc={banner_section?.banner_image} altText={banner_section?.title} imagetransforms={processedImages} renderer="bgImg" imagename={imagename} strapi_id={GQLPage?.id} classNames="banner_img" />}    

        <span className="transp_bg"></span>

        {GQLPage?.menu?.slug !== "home" && <BreadCrumbs GQLPage={GQLPage}/> }

        <Container>
          <Row>
            <Col className="md-12">
              
              {(GQLPage?.choose_form === "none" || GQLPage?.choose_form === undefined) ?
                    <div className="banner_inner">
                      <div className="banner-content">
                        {banner_section?.title && <h1>{banner_section.title}</h1> }

                        {banner_section?.content && 
                        <span className="sm-text">
                          <ContentModule Content={banner_section.content}/>
                        </span>
                        }

                        {(banner_section?.button_cta_1?.label || banner_section?.button_cta_2?.label) && 
                          <BannerBtns banner_section={banner_section} strapi_id={GQLPage?.id}/>
                        }
                        
                        {banner_section?.show_reviews && 
                          <ReviewRatings />
                        }

                        {banner_section?.show_search === "property_search" && 
                          <BannerSearch GQLPage={GQLPage} />
                        }
                      </div>

                      {GQLModules?.length > 0 && GQLModules?.map((Module, index) => {
                          return(
                              <>
                                  {Module.__typename === "GLSTRAPI_ComponentComponentsValuationSection" && <ValuationPage key={Module} Module={Module} /> }
                              </>              
                          )
                      })}
                    </div>                  
                :
                  <FormsWrapper form_data={banner_section} form_type={GQLPage?.choose_form} location={location} to_email_id={GQLPage?.form_to_email_id} menu_slug={GQLPage?.menu?.slug} description={GQLPage?.content} title={GQLPage?.title} />
              }

            </Col>
          </Row>
        </Container>
      </section>

  );
};
export default Banner;

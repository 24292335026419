import React from 'react'


const MarkerIcon = (props) => (
    
<svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="10" cy="10" r="4" fill="white"/>
<path d="M9.99984 0.666748C7.52448 0.666748 5.15051 1.65008 3.40017 3.40042C1.64983 5.15076 0.666504 7.52473 0.666504 10.0001C0.666504 16.6667 9.99984 27.3334 9.99984 27.3334C9.99984 27.3334 19.3332 16.6667 19.3332 10.0001C19.3332 7.52473 18.3498 5.15076 16.5995 3.40042C14.8492 1.65008 12.4752 0.666748 9.99984 0.666748ZM9.99984 13.3334C9.34057 13.3334 8.6961 13.1379 8.14794 12.7716C7.59977 12.4054 7.17253 11.8848 6.92024 11.2757C6.66795 10.6666 6.60194 9.99638 6.73055 9.34978C6.85917 8.70318 7.17664 8.10923 7.64281 7.64306C8.10899 7.17688 8.70293 6.85941 9.34954 6.7308C9.99614 6.60218 10.6664 6.66819 11.2754 6.92048C11.8845 7.17277 12.4051 7.60002 12.7714 8.14818C13.1377 8.69634 13.3332 9.34081 13.3332 10.0001C13.3332 10.4378 13.247 10.8713 13.0794 11.2757C12.9119 11.6801 12.6664 12.0476 12.3569 12.3571C12.0473 12.6666 11.6799 12.9122 11.2754 13.0797C10.871 13.2472 10.4376 13.3334 9.99984 13.3334Z" fill={props.pinColor ? props.pinColor : "#2B2B2B"}/>
</svg> 

)


export {
    MarkerIcon
}